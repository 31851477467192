import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

new Swiper('.products-swiper', {
    modules: [Navigation, Pagination, EffectFade],
    loop: true,
    autoHeight: true,
    navigation: {
        nextEl: '.btn-swiper-navigation-next',
        prevEl: '.btn-swiper-navigation-prev',
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
});
