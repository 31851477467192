import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('#personal_data_billTypeCheckbox').on('change', function () {
    if ($(this).is(':checked')) {
        $('[name="personal_data[billPos]"]').closest('.form-group').prop('disabled', true);
        $('[name="personal_data[billType]"]').val('Faktura');
    } else {
        $('[name="personal_data[billPos]"]').closest('.form-group').prop('disabled', false);
        $('[name="personal_data[billType]"]').val('Paragon');
    }
});

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.application-form').on('submit-success', function(event, data) {
    $('.result').addClass('d-none');

    $(`.result-${data.prize ? 'success' : 'failed'}`).removeClass('d-none');
    if (data.prize) {
        $('.result-prize-name').html(data.prize.name);
        $('.result-form-link').attr('href', data.formUrl);
    }
    setRegisteredData(data, 'section#application');

    $('#modal-form-application').modal('show');
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[birthDay]"]',
        '[name="personal_data[extraData][citizenship]"]',
        '[name="personal_data[extraData][street]"]',
        '[name="personal_data[extraData][streetNr]"]',
        '[name="personal_data[extraData][flatNr]"]',
        '[name="personal_data[extraData][zipCode]"]',
        '[name="personal_data[extraData][city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));

    if ($(this).prop('checked')) {
        $(fields.join(', '), this.form.elements).closest('.form-row').removeClass('d-none');
    } else {
        $(fields.join(', '), this.form.elements).closest('.form-row').addClass('d-none');
    }
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#winner');

    // page swap
    $('.page').find('form').remove();
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');
    $('.after-fill').removeClass('d-none');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-contact').modal('show');
});

$('#modal-form-winner').on('hidden.bs.modal', function(e) {
    $('.page-thank-you').addClass('d-none');
    $('.page-thank-you').css('display', 'none');
});
